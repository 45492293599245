import { useQuery } from '@tanstack/react-query';
import Auth from '../authentication/Auth';
import { Nav, Navbar, Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { getFeatures } from '../scripts/DataServices';

export const NavMenu = () => {
    const location = useLocation();

    const isOnLoginPage = (): boolean => {
        return (
            location.pathname.includes('/login') ||
            location.pathname.includes('/forgot-password') ||
            location.pathname.includes('/reset-password') ||
            location.pathname.includes('/confirm-email')
        );
    };

    const features = useQuery({ queryKey: ['features'], queryFn: getFeatures });

    return (
        <Navbar className="mb-4" bg="dark" expand="lg" variant="dark">
            <Container fluid="xxl">
                <Navbar.Brand className="text-white" href="/">
                    SmartMail
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse className="justify-end" id="basic-navbar-nav">
                    {!isOnLoginPage() && (
                        <Nav>
                            <Nav.Link className="text-white mx-2" href="/">
                                Regler
                            </Nav.Link>
                            <Nav.Link className="text-white mx-2" href="/priorities">
                                Regelprioritering
                            </Nav.Link>
                            <Nav.Link className="text-white mx-2" href="/sorting-test">
                                Sorteringstest
                            </Nav.Link>
                            <Nav.Link className="text-white mx-2" href="/auditlogs">
                                Fremsøgning
                            </Nav.Link>
                            <Nav.Link className="text-white mx-2" href="/sharedinboxes">
                                Postkasser
                            </Nav.Link>
                            <Nav.Link className="text-white mx-2" href="/statistics">
                                Statistik
                            </Nav.Link>
                            <Nav.Link className="text-white mx-2" href="/ai">
                                AI
                            </Nav.Link>
                            {features.data?.onboarding && (
                                <Nav.Link className="text-white mx-2" href="/onboarding">
                                    Onboarding
                                </Nav.Link>
                            )}
                            <Nav.Link className="text-white mx-2" href="/roles">
                                Admin
                            </Nav.Link>
                            <Nav.Link className="text-white border-l border-white mx-2" href="/account">
                                Min Konto
                            </Nav.Link>
                        </Nav>
                    )}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
