import styles from './FormProgress.module.scss';

type ProgressProps = {
    states: string[];
    step: number;
};

export const FormProgress = ({ states, step }: ProgressProps) => {
    return (
        <div className={styles.StepWrapperHorizontal}>
            {states.map((s, i) => (
                <div key={i} className={styles.StepWrapper}>
                    <div className={`${styles.StepNumber} ${i <= step ? styles.StepNumberActive : styles.StepNumberDisabled}`}>
                        {i < step ? <span>&#10003;</span> : i + 1}
                    </div>
                    <div className={`${styles.StepDescription} ${i === step ? styles.StepDescriptionActive : ''}`}>{s}</div>
                    {i !== states.length - 1 ? <div className={`${styles.StepDivider} ${i < step ? styles.StepDividerActive : ''}`} /> : null}
                </div>
            ))}
        </div>
    );
};
