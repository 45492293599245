import { ColumnDef, flexRender, getCoreRowModel, Row, useReactTable } from '@tanstack/react-table';
import { FC, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { ConnectableElement, DndProvider, useDrag, useDrop, XYCoord } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IRule } from '../../scripts/ApiTypes';
import { testPriorityChange } from '../../scripts/DataServices';
import { useQuery } from '@tanstack/react-query';
import { PriorityTestResults } from './PriorityTestResults';
import FadeLoader from 'react-spinners/ClipLoader';
import { DateRange } from '../Utility/DateRange';
import { RuleForMailbox } from '../../hooks/UseGetRulesForMailbox';

const DraggableRow: FC<{ row: Row<RuleForMailbox>; reorderRow: (draggedRowIndex: number, targetRowIndex: number) => void }> = ({ row, reorderRow }) => {
    const [{ isOver, offset }, dropRef] = useDrop({
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            offset: monitor.getDifferenceFromInitialOffset(),
        }),
        accept: 'row',
        drop: (draggedRow: Row<IRule>) => reorderRow(draggedRow.index, row.index),
    });

    const [{ isDragging }, dragRef] = useDrag({
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        item: () => row,
        type: 'row',
    });

    const dragDropRef = (element: ConnectableElement) => {
        dragRef(element);
        dropRef(element);
    };

    const setBorderType = (offset: XYCoord) => {
        if (offset.y < 0) {
            return { borderTop: '1px solid black' };
        } else {
            return { borderBottom: '1px solid black' };
        }
    };

    return (
        <tr ref={dragDropRef} style={{ opacity: isDragging ? 0.5 : 1 }} className="hover:bg-gray-200">
            {row.getVisibleCells().map((cell) => (
                <td key={cell.id} style={isOver && offset ? setBorderType(offset) : {}}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
            ))}
        </tr>
    );
};

type props = {
    rules: RuleForMailbox[];
    saveChanges: (ids: number[]) => void;
};

const EditPriorityTable = ({ rules, saveChanges }: props) => {
    const [items, setItems] = useState<RuleForMailbox[]>(rules.sort((a, b) => a.priority - b.priority));
    const [testFrom, setTestFrom] = useState<Date>(new Date(Date.now() - 7 * 24 * 60 * 60 * 1000));
    const [testTo, setTestTo] = useState<Date>(new Date(Date.now()));

    const priorityTest = useQuery({
        queryKey: ['priority-test', items],
        queryFn: () =>
            testPriorityChange({
                ruleIds: items.map((r) => r.rule.id),
                from: testFrom,
                to: testTo,
            }),

        enabled: false,
    });

    const columns: ColumnDef<RuleForMailbox>[] = [
        {
            accessorFn: (row) => row.priority + 1,
            id: 'priority',
            header: 'Nr',
        },
        {
            accessorFn: (row) => row.rule.name,
            id: 'name',
            header: 'Navn',
        },
        {
            accessorFn: (row) => (row.rule.fallthrough ? 'Aktiveret' : 'Deaktiveret'),
            id: 'ruleText',
            header: 'Regelmotor fortsættelse',
        },
    ];

    const table = useReactTable({
        data: items,
        columns,
        getCoreRowModel: getCoreRowModel(),
    });

    const reorderRow = (draggedRowIndex: number, targetRowIndex: number) => {
        rules.splice(targetRowIndex, 0, rules.splice(draggedRowIndex, 1)[0] as RuleForMailbox);
        setItems([...rules]);
    };

    const savePriorities = () => {
        saveChanges(items.map((r) => r.rule.id));
    };

    return (
        <>
            <div className="shadow-sm">
                <Table className="border-separate border-spacing-0 bg-gray-100">
                    <thead className="bg-main-500 text-gray-100">
                        <tr>
                            {table.getFlatHeaders().map((header) => (
                                <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        <DndProvider backend={HTML5Backend}>
                            {table.getRowModel().rows.map((row) => (
                                <DraggableRow key={row.id} row={row} reorderRow={reorderRow} />
                            ))}
                        </DndProvider>
                    </tbody>
                </Table>
            </div>

            <p>Test ændringer i perioden:</p>
            <div className="flex mb-2">
                <DateRange to={testTo} from={testFrom} setTo={setTestTo} setFrom={setTestFrom} />
                <Button className="ml-2" onClick={() => priorityTest.refetch()}>
                    Test ændringer
                </Button>
            </div>
            {priorityTest.isLoading && priorityTest.fetchStatus !== 'idle' && <FadeLoader />}
            {priorityTest.data && <PriorityTestResults result={priorityTest.data} />}
            <Button className="mr-2" onClick={savePriorities}>
                Gem rækkefølge
            </Button>
        </>
    );
};

export default EditPriorityTable;
