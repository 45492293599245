import { useQuery } from '@tanstack/react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import { fetchRuleHistory } from '../../scripts/DataServices';
import { ClipLoader } from 'react-spinners';
import { IRuleHistoryEntry } from '../../scripts/ApiTypes';
import { Accordion } from 'react-bootstrap';
import { getRuleDescription } from '../RuleCreation/RuleSummation';

export const RuleDetails = () => {
    const { id } = useParams();
    return id ? <RuleHistoryView id={+id} /> : <p>Der findes ingen regler der svare til dette url</p>;
};

const RuleHistoryView = ({ id }: { id: number }) => {
    const ruleHistory = useQuery({ queryKey: ['history', id], queryFn: () => fetchRuleHistory(+id) });

    return (
        <div className="shadow-md p-4">
            <>
                {ruleHistory.error && <p>Der opstod en uventet fejl da regeldataen skulle hentes</p>}
                {ruleHistory.isLoading && <ClipLoader />}
                {ruleHistory.data && (
                    <>
                        <h1 className="mb-2">Regel: {ruleHistory.data.versions[0].name}</h1>
                        <table className="mb-2 w-full">
                            <colgroup>
                                <col width={'15%'} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td className="py-2">Oprettet af:</td>
                                    <td className="py-2">{ruleHistory.data.createdBy}</td>
                                </tr>
                                <tr>
                                    <td className="py-2">Oprettet:</td>
                                    <td className="py-2">{new Date(ruleHistory.data.createdOn).toLocaleString()}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h3>Versioner:</h3>
                        <HistoryList history={ruleHistory.data.versions} />
                    </>
                )}
            </>
        </div>
    );
};

const HistoryList = ({ history }: { history: IRuleHistoryEntry[] }) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const dateParam = searchParams.get('ts');
    const versionDate = dateParam ? new Date(dateParam) : new Date(Date.now());

    const defaultKey = history.findIndex((V) => new Date(V.timestamp) < versionDate);

    const createStateText = (state: number): string => {
        switch (state) {
            case 1:
                return 'Aktiveret';
            case 2:
                return 'Deaktiveret';
            case 3:
                return 'Slettet';
            default:
                return 'Ukendt status';
        }
    };

    return (
        <Accordion defaultActiveKey={defaultKey + ''}>
            {history.map((version, i) => (
                <Accordion.Item key={i} eventKey={i.toString()}>
                    <Accordion.Header>
                        Version {history.length - i}: {new Date(version.timestamp).toLocaleString()}
                    </Accordion.Header>
                    <Accordion.Body>
                        <table className="w-full">
                            <colgroup>
                                <col width={'20%'} />
                            </colgroup>
                            <tbody>
                                <tr>
                                    <td className="py-2">Navn:</td>
                                    <td className="py-2">{version.name}</td>
                                </tr>
                                <tr>
                                    <td className="py-2">Status:</td>
                                    <td className="py-2">{createStateText(version.state)}</td>
                                </tr>
                                <tr>
                                    <td className="py-2">Beskrivelse:</td>
                                    <td className="py-2">{getRuleDescription(version.ruleLogic)}</td>
                                </tr>
                                <tr>
                                    <td className="py-2">Redigeret af:</td>
                                    <td className="py-2">{version.modifiedBy}</td>
                                </tr>
                                <tr>
                                    <td className="py-2">Redigeret:</td>
                                    <td className="py-2">{new Date(version.timestamp).toLocaleString()}</td>
                                </tr>
                                <tr>
                                    <td className="py-2">Regel motorfortsættelse:</td>
                                    <td className="py-2">{version.fallThrough ? 'Aktiveret' : 'Deaktiveret'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
};
