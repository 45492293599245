import React, { Component, Fragment } from 'react';
import Auth from '../../../authentication/Auth';

export const Profile = ({ userName }: { userName: string }) => {
    return (
        <>
            <h4>Profil</h4>
            <div className="row">
                <div className="col-md-6">
                    <form>
                        <div className="form-group">
                            <label>Brugernavn</label>
                            <input className="form-control" disabled={true} value={userName}></input>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
