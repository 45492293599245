import { useQuery } from '@tanstack/react-query';
import Button from 'react-bootstrap/Button';
import { ClipLoader } from 'react-spinners';
import { getSharedMailboxes } from '../../scripts/DataServices';
import { SharedMailBoxTable } from './SharedMailBoxTable';

export const SharedMailboxes = () => {
    const mailboxes = useQuery({ queryKey: ['mailboxes'], queryFn: getSharedMailboxes });
    return (
        <div>
            <h1>Postkasser </h1>
            <p>
                Denne liste repræsenterer de postkasser som er registreret i SmartMail systemet. Det vil sige, postkasserne som AI-modellen kan fordele til og
                postkasserne som regler kan kopiere mails til. Hvis forbindelsesstatus er rød kan det skyldes at SmartMail ikke har fået tildelt adgang til
                postkassen. Kontakt din Exchange administrator for at få tildelt adgange.
            </p>
            <>
                {mailboxes.error && <p>Der opstod en intern fejl da postkasserne skulle fremsøges</p>}
                {mailboxes.isLoading && (
                    <div className="my-2 w-full flex justify-center">
                        <ClipLoader />
                    </div>
                )}
                {mailboxes.data && <SharedMailBoxTable mailboxes={mailboxes.data} />}
            </>
            <Button href="/add-mailbox">Tilføj postkasse</Button>
        </div>
    );
};
