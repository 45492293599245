import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ClipLoader } from 'react-spinners';
import { getSharedMailboxes } from '../../scripts/DataServices';
import { Action } from './RuleCreation';

type FormProps = {
    nextPage: () => void;
    prevPage: () => void;
    setActions: (a: Action[]) => void;
    actions: Action[];
};

export const ActionsForm = ({ nextPage, prevPage, setActions, actions }: FormProps) => {
    const mailboxes = useQuery({
        queryKey: ['mailboxes'],
        queryFn: getSharedMailboxes,
        select: (data) => (data.length === 0 ? [] : data.map((mb) => mb.mailbox)),
    });

    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            nextPage();
        }
        setValidated(true);
    };

    const addAction = () => {
        actions.push({
            actionType: 'forward_to',
            parameters: [''],
        });
        setActions([...actions]);
    };

    const removeAction = () => {
        actions.pop();
        setActions([...actions]);
    };

    return (
        <>
            <h2 className="text-center">Tilføj handlinger</h2>
            <Form className="text-center" noValidate validated={validated} onSubmit={handleSubmit}>
                <>
                    {mailboxes.error && <p>Der er opstået en intern fejl i systemet</p>}
                    {mailboxes.isLoading && <ClipLoader />}
                    {mailboxes.data && (
                        <Form.Group className="py-4 border-y-2 mb-4">
                            {actions.map((e, i) => (
                                <ActionInput
                                    key={i}
                                    autoSuggesterValues={mailboxes.data}
                                    action={e}
                                    setActionType={(s: string) => {
                                        actions[i].actionType = s;
                                        setActions([...actions]);
                                    }}
                                    setActionValue={(s: string[]) => {
                                        let trimmed = s.map((mail) => mail.trim());
                                        actions[i].parameters = trimmed;
                                        setActions([...actions]);
                                    }}
                                />
                            ))}
                            <div className="flex justify-center">
                                <Button className="mx-2" disabled={actions.length === 1} variant="danger" onClick={removeAction}>
                                    Fjern handling
                                </Button>
                                <Button className="mx-2" onClick={addAction}>
                                    Tilføj handling
                                </Button>
                            </div>
                        </Form.Group>
                    )}
                </>
                <div className="flex justify-between">
                    <Button variant="secondary" onClick={prevPage}>
                        Tilbage
                    </Button>
                    <Button type="submit">Næste</Button>
                </div>
            </Form>
        </>
    );
};

type ActionInputProps = {
    action: Action;
    setActionType: (s: string) => void;
    setActionValue: (s: string[]) => void;
    autoSuggesterValues: string[];
};

const ActionInput = ({ action, setActionType, setActionValue, autoSuggesterValues }: ActionInputProps) => {
    const ActionControl = () => {
        if (action.actionType === 'forward_to')
            return <Form.Control value={action.parameters[0]} required onChange={(e) => setActionValue([e.currentTarget.value])} placeholder="email..." />;
        if (action.actionType === 'copy_to_folder')
            return (
                <Form.Control value={action.parameters[0]} required onChange={(e) => setActionValue([e.currentTarget.value])} placeholder="sti til mappe..." />
            );
        if (action.actionType === 'copy_to_sub_mailbox')
            return (
                <Form.Select value={action.parameters[0]} onChange={(e) => setActionValue([e.currentTarget.value])}>
                    {autoSuggesterValues.map((v, i) => (
                        <option key={i} value={v}>
                            {v}
                        </option>
                    ))}
                </Form.Select>
            );
    };

    return (
        <Form.Group className="flex mb-2">
            <Form.Select
                className="h-min w-1/2"
                onChange={(e) => {
                    setActionType(e.currentTarget.value);
                    // In case the user changes the type to the select field but doesn't change the select to trigger the onChange handler
                    if (e.currentTarget.value === 'copy_to_sub_mailbox') {
                        setActionValue([autoSuggesterValues[0]]);
                    } else {
                        setActionValue(['']);
                    }
                }}
                defaultValue={action.actionType}
            >
                <option value="forward_to">Videresend til</option>
                <option value="copy_to_folder">Kopier til mappe</option>
                <option value="copy_to_sub_mailbox">Videresend til underpostkasse</option>
            </Form.Select>
            <Form.Group className="w-1/2">
                {ActionControl()}
                <Form.Control.Feedback type="invalid">Indtast en værdi.</Form.Control.Feedback>
            </Form.Group>
        </Form.Group>
    );
};
