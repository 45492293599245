import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { createShareFolder, getMailboxConnection } from '../../scripts/DataServices';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import FadeLoader from 'react-spinners/ClipLoader';
import { useNavigate } from 'react-router-dom';

export const AddMailBoxForm = () => {
    const [validated, setValidated] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const navigate = useNavigate();

    const connection = useQuery({ queryKey: ['mailbox-connection', email], queryFn: () => getMailboxConnection(email), enabled: false });

    const handleSubmit = (e: any) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (form.checkValidity()) {
            connection.refetch();
            setValidated(true);
        }
    };

    const addMailbox = () => {
        createShareFolder({
            mailbox: email,
            method: 'copy',
        })
            .then((res) => {
                navigate('/sharedinboxes');
            })
            .catch((error) => {
                if (error.response.status === 409) {
                    window.alert('Denne postkasse er allerede registreret');
                }
            });
    };

    const formPage = (
        <Form validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-2">
                <Form.Label htmlFor="mailboxInput">Ny postkasse</Form.Label>
                <Form.Control className="mb-2" required onChange={(e) => setEmail(e.currentTarget.value)} id="mailboxInput" placeholder="email" type="email" />
                <Form.Text className="text-muted">
                    Obs! Tilføjede postkasser bliver ikke automatisk inlkuderet i AI fordelingen, men kan bruges når man opretter nye regler. Når postkassen er
                    tilføjet og SmartMail kan forbinde til den, begynder SmartMail at samle data fra postakssen som senere kan bruges til at gentræne AI
                    modellen. Mængden af indsamlet data kan du se på AI siden.
                </Form.Text>
            </Form.Group>
            <Button type="submit">Næste</Button>
        </Form>
    );

    const confirmPage = (
        <div>
            {connection.isLoading && <FadeLoader />}
            <p>
                Postkasse: {email}{' '}
                <span>
                    {connection.data !== undefined &&
                        (connection.data ? <FontAwesomeIcon color="green" icon={faCheckCircle} /> : <FontAwesomeIcon color="red" icon={faExclamationCircle} />)}
                </span>
            </p>
            <p>
                Forbindelsesstatus:{' '}
                {connection !== undefined &&
                    (connection.data
                        ? 'Smartmail har forbindelse til postkassen'
                        : 'Smartmail har ikke forbindelse til postkassen. Regler der kopiere til denne postkasse vil flytte mails til manuelbehandling')}
            </p>
            <Button className="mr-2" variant="secondary" onClick={() => setValidated(false)}>
                Tilbage
            </Button>
            <Button onClick={addMailbox}>Tilføj postkasse</Button>
        </div>
    );

    return (
        <>
            <div className="shadow-md p-4">
                <h1>Tilføj ny postkasse</h1>
                {!validated ? formPage : confirmPage}
            </div>
        </>
    );
};
