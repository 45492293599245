import { useQuery } from '@tanstack/react-query';
import { changeEmail } from '../../scripts/AuthenticationServices';

export const Confirm = () => {
    //const x = React.useMemo(async () => {
    //    const query = new URL(window.document.location.toString()).searchParams;
    //    return await changeEmail({ newEmail: query.get('email') ?? '', token: query.get('token') ?? '', username: query.get('username') ?? '' });
    //}, []);
    const query = new URL(window.document.location.toString()).searchParams;

    const result = useQuery({
        queryKey: ['email-change'],
        queryFn: () => changeEmail({ newEmail: query.get('email') ?? '', token: query.get('token') ?? '', username: query.get('username') ?? '' }),
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 300000,
    });

    return (
        <div className="shadow-md p-4">
            <h1 className="text-center">Ændring af email</h1>
            <p className="text-center">
                {result.isError && 'Der opstod en fejl. Din mail blev ikke ændret'}
                {result.isLoading && 'Bekræfter ny email...'}
                {result.isSuccess && 'Din email er bekræftet. Du kan nu lukke denne fane.'}
            </p>
        </div>
    );
};
