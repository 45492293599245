import { Navigate, Outlet } from 'react-router-dom';
import Auth from './Auth';

export const Protected = () => {
    if (Auth.getIsAuthenticated()) {
        return <Outlet />;
    } else {
        return <Navigate to={'/login'} />;
    }
};
