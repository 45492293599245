import ApiFetcher from './ApiHelpers';
import axios from 'axios';
import {
    IAuditLog,
    IAuditLogDetails,
    IAuditLogFilter,
    IAuditLogList,
    IDestination,
    IDestinationCreateBody,
    IFeatures,
    IMailValues,
    IOnboardingJobStatus,
    IPeriodStatistics,
    IRule,
    IRuleCreation,
    IRuleDetails,
    IRuleHistory,
    IRulePriorityChange,
    IRulePriorityTest,
    IRuleStateUpdate,
    IRuleTest,
    ISmartMailTestResults,
    IStatisticsFilter,
    IUploadTestResult,
    IXAIResult,
    ModelStatistics,
} from './ApiTypes';
import ApiHelpers from './ApiHelpers';

export const api = axios.create({
    baseURL: '/api/',
    headers: {
        'Content-type': 'application/json; charset=UTF-8',
    },
});

api.interceptors.request.use(
    async (config) => {
        const headers = await ApiFetcher.getHeaders();
        config.headers = { ...headers, ...config.headers };
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    async (response) => {
        if (response.status === 401) {
            window.location.href = '/login';
        }
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            window.location.href = '/login';
        }
        if (error.response.status === 400) {
            // This probaly catches too many errors
            await ApiHelpers.getAntiForgeryToken();
        }
        return Promise.reject(error);
    }
);

export const fetchAuditLogs = async (filter: IAuditLogFilter): Promise<IAuditLogList> => {
    const response = await api.post('auditlogs/', filter);
    return response.data;
};

export const fetchRules = async (): Promise<IRule[]> => {
    const response = await api.get('rules');
    return response.data;
};

export const putRulePriorities = async (ids: number[], mailboxId: number) => {
    const response = await api.put('rules/update-priorities', {
        ruleids: ids,
        mailboxId: mailboxId,
    });
    return response.status;
};

export const fetchPeriodStatistics = async (filter: IStatisticsFilter): Promise<IPeriodStatistics> => {
    const response = await api.post('auditlogs/statistics-from-period/', filter);
    return response.data;
};

export const testRuleImpact = async (ruleTest: IRuleTest): Promise<IAuditLog[]> => {
    const response = await api.post('rules/test', ruleTest);
    return response.data;
};

export const testPriorityChange = async (priorityTest: IRulePriorityTest): Promise<IRulePriorityChange[]> => {
    const response = await api.post('rules/update-priorities/test', priorityTest);
    return response.data;
};

export const getSharedMailboxes = async (): Promise<IDestination[]> => {
    const response = await api.get('mailbox');
    return response.data;
};

export const getSharedMailboxesWithConnectionStatus = async (): Promise<IDestination[]> => {
    const response = await api.get('mailbox/connection');
    return response.data;
};

export const getMailboxConnection = async (address: string): Promise<boolean> => {
    const response = await api.get(`mailbox/connection/${address}`);
    return response.data;
};

export const updateRuleState = async (ruleUpdate: IRuleStateUpdate) => {
    const response = await api.put('rules/update-state/', ruleUpdate);
    return response.status;
};

export const updateRule = async (ruleId: number, rule: IRuleCreation) => {
    const response = await api.put(`rules/${ruleId}`, rule);
    return response;
};

export const fetchRuleDetails = async (ruleId: number): Promise<IRuleDetails> => {
    const response = await api.get(`rules/${ruleId}`);
    return response.data;
};

export const deleteRule = async (ruleId: number) => {
    const response = await api.delete(`rules/${ruleId}`);
    return response;
};

export const createShareFolder = (mailbox: IDestinationCreateBody): Promise<any> => {
    return api.post('mailbox', mailbox);
};

export const createRule = async (rule: IRuleCreation) => {
    const response = await api.post('rules/', rule);
    return response;
};

export const deleteSharedMailbox = async (mailboxId: number): Promise<number> => {
    const response = await api.delete(`mailbox/${mailboxId}`);
    return response.status;
};

export const fetchRuleHistory = async (ruleId: number): Promise<IRuleHistory> => {
    const response = await api.get(`rules/history/` + ruleId);
    return response.data;
};

export const fetchXAI = async (text: string): Promise<IXAIResult> => {
    const response = await api.post(`xai`, text);
    return response.data;
};

export const postSmartMailTest = async (data: IMailValues): Promise<ISmartMailTestResults> => {
    const response = await api.post('sortingtest', data);
    return response.data;
};

export const postFileTest = async (file: File | undefined, mailboxId: number): Promise<IUploadTestResult> => {
    if (file) {
        const formData = new FormData();
        formData.append('FormFile', file);
        formData.append('FileName', file.name);
        formData.append('MailboxId', mailboxId.toString());
        const response = await api.post('sortingtest/upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data;
    } else {
        //TODO: Not sure how great this is
        throw new Error();
    }
};

export const startOnboardingTest = async () => {
    const response = await api.get('hangfire/dry');
    return response.status;
};

export const getOnboardingStatus = async () => {
    const response = await api.get<IOnboardingJobStatus>('hangfire/dry-status');
    return response.data;
};

export const getFeatures = async () => {
    const response = await api.get<IFeatures>('feature');
    return response.data;
};

export const getDestinationsWithDetails = async () => {
    const response = await api.get<IDestination[]>('mailbox/mailcount');
    return response.data;
};

export const getAiThreshold = async () => {
    const response = await api.get<number>('model/threshold');
    return response.data;
};

export const changeAiThreshold = async (threshold: number) => {
    const response = await api.patch('model/threshold', {
        value: threshold,
    });
    return response.status;
};

export const getModelStatistics = async () => {
    const response = await api.get<ModelStatistics>('model/stats');
    return response.data;
};

export const getDisclaimerText = async () => {
    const response = await api.get<string>('model/disclaimer');
    return response.data;
};

export const setDisclaimerText = async (text: string) => {
    const response = await api.patch('model/disclaimer', {
        value: text,
    });
    return response.status;
};
