import { useQuery } from '@tanstack/react-query';
import { changeAiThreshold, getAiThreshold, getDestinationsWithDetails, getModelStatistics } from '../../scripts/DataServices';
import { Alert, Button, Form, Modal } from 'react-bootstrap';
import { MailBoxCard } from './MailBoxCard';
import { ClipLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { ThresholdStackedGraph, modelDataEntry } from './ThresholdStackedGraph';
import { ThresholdMailboxGraph, thresholdMailboxDataEntry } from './ThresholdMailBoxGraph';
import DisclaimerText from './DisclaimerTextEdit';

export const AIControlPanel = () => {
    const destinations = useQuery({ queryKey: ['desinations'], queryFn: getDestinationsWithDetails });

    const [aiThreshold, setAiThreshold] = useState<number>(0);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [thresholdUpdateError, setThresholdUpdateError] = useState<boolean>(false);
    const [tresholdUpdateSubmitting, setThresholdUpdateSubmitting] = useState<boolean>(false);

    const aiThresholdQuery = useQuery({
        queryKey: ['threshold'],
        queryFn: getAiThreshold,
    });

    useEffect(() => {
        if (aiThresholdQuery.isSuccess) {
            if (aiThresholdQuery.data < 0) {
                setAiThreshold(0);
            } else if (aiThresholdQuery.data > 1) {
                setAiThreshold(100);
            } else {
                setAiThreshold(aiThresholdQuery.data * 100);
            }
        }
    }, [aiThresholdQuery.isSuccess]);

    const roundToTwoDecimals = (number: number) => {
        return Math.round((number + Number.EPSILON) * 100) / 100;
    };

    const modelStats = useQuery({
        queryKey: ['modelstats'],
        queryFn: getModelStatistics,
        select: (data) => {
            let graph: thresholdMailboxDataEntry[][] = [];

            let g: modelDataEntry[] = [];

            for (let i = 0; i < data.length; i++) {
                let threshold = data[i];
                graph[i] = [];
                let sup: modelDataEntry = {
                    Korrekt: 0,
                    Inkorrekt: 0,
                    Manuel: 0,
                };
                for (let mailbox in threshold) {
                    let total = threshold[mailbox].manual + threshold[mailbox].incorrect + threshold[mailbox].correct;
                    sup.Manuel += threshold[mailbox].manual;
                    sup.Korrekt += threshold[mailbox].correct;
                    sup.Inkorrekt += threshold[mailbox].incorrect;
                    graph[i].push({
                        Mailbox: mailbox,
                        Inkorrekt: threshold[mailbox].incorrect / total ?? 0,
                        Korrekt: threshold[mailbox].correct / total ?? 0,
                        Manuel: threshold[mailbox].manual / total ?? 0,
                    });
                }
                let supTotal = sup.Manuel + sup.Korrekt + sup.Inkorrekt;
                sup.Manuel = sup.Manuel / supTotal ?? 0;
                sup.Korrekt = sup.Korrekt / supTotal ?? 0;
                sup.Inkorrekt = sup.Inkorrekt / supTotal ?? 0;
                g[i] = sup;
            }

            return {
                bar: graph,
                stacked: g,
            };
        },
    });

    const submitThresholdChange = async () => {
        setThresholdUpdateSubmitting(true);
        setModalShow(false);
        let changeResponse = await changeAiThreshold(aiThreshold / 100);
        if (changeResponse == 200) {
            setThresholdUpdateSubmitting(false);
        } else {
            setThresholdUpdateError(true);
            setThresholdUpdateSubmitting(false);
        }
    };

    return (
        <div>
            {thresholdUpdateError && <Alert variant={'danger'}>Der opstod en uventet fejl. Prøv igen senere eller kontakt en administrator</Alert>}
            <h1>AI kontrolpanel</h1>
            <div className="flex flex-col items-center mb-6 w-3/4 mx-auto">
                <p className="text-xl">Accepter AI fordeling med en sikkerhed på over</p>
                <p className="text-5xl">{aiThreshold} %</p>
                <Form.Range className="mb-2" value={aiThreshold} onChange={(e) => setAiThreshold(+e.currentTarget.value)}></Form.Range>
                <div className="relative flex justify-center">
                    <Button disabled={tresholdUpdateSubmitting} onClick={() => setModalShow(true)}>
                        Gem grænseværdi
                    </Button>
                    {tresholdUpdateSubmitting && <ClipLoader size={24} className="absolute top-1/2 left-1/2 -mt-3 -ml-3" />}
                </div>
            </div>
            <DisclaimerText></DisclaimerText>

            {modelStats.isLoading && (
                <div className="mx-auto w-20">
                    <ClipLoader size={80}></ClipLoader>
                </div>
            )}
            {modelStats.isError && <p>Modeldata kunne ikke hentes fra serveren</p>}
            {modelStats.data && <ThresholdMailboxGraph data={modelStats.data.bar[aiThreshold]}></ThresholdMailboxGraph>}
            {modelStats.data && <ThresholdStackedGraph data={modelStats.data.stacked}></ThresholdStackedGraph>}
            <h2>Postkasser</h2>
            <div>
                {destinations.isError && <p>Der opstod en uventet fejl</p>}
                {destinations.isLoading && (
                    <div className="mt-10 flex justify-center">
                        <ClipLoader size={50} />
                    </div>
                )}
                {destinations.data && destinations.data.map((d) => <MailBoxCard key={d.id} mailbox={d} />)}
            </div>
            <Modal show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Opdater AI grænseværdi</Modal.Title>
                </Modal.Header>
                <Modal.Body>Er du sikker på at du vil ændre AI grænseværdi? Dette vil ændre den fremtidige postfordeling.</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalShow(false)}>
                        Fotryd
                    </Button>
                    <Button variant="primary" onClick={submitThresholdChange}>
                        Gem grænseværdi
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
