type StatBoxProps = {
    count: string | number;
    description: string;
};

export const StatisticsCountBox = ({ count, description }: StatBoxProps) => {
    return (
        <div>
            <h4 style={{ textAlign: 'center' }}>{count}</h4>
            <p className="statistic-description">{description}</p>
        </div>
    );
};
