import { useEffect, useState } from 'react';
import { MainMailboxSelect } from '../Utility/MainMailboxSelect';
import { RuleForMailbox, UseGetRulesForMailbox } from '../../hooks/UseGetRulesForMailbox';
import { MainMailbox, UseGetMainMailboxes } from '../../hooks/UseGetMainMailboxes';
import { PriorityTable } from './PriorityTable';
import { Alert, Button } from 'react-bootstrap';
import EditPriorityTable from './EditPriorityTable';
import { UseMutateRulePriorities } from '../../hooks/UseMutateRulePriorities';
import { ClipLoader } from 'react-spinners';

export function RulePriorityNew() {
    const mainMailboxes = UseGetMainMailboxes();

    return (
        <>
            <h1>Regelprioritering</h1>
            {mainMailboxes.isLoading && (
                <div className="flex justify-center">
                    <ClipLoader />
                </div>
            )}
            {mainMailboxes.data && <TableStateWrapper mainMailboxes={mainMailboxes.data}></TableStateWrapper>}
        </>
    );
}

function TableStateWrapper({ mainMailboxes }: { mainMailboxes: MainMailbox[] }) {
    const [selectedMailbox, setSelectedMailbox] = useState<number>(mainMailboxes[0].id);
    const RulesForMailbox = UseGetRulesForMailbox(selectedMailbox);
    const [editting, setEditting] = useState<boolean>(false);
    const mutatePriority = UseMutateRulePriorities(selectedMailbox);

    useEffect(() => {
        if (mutatePriority.isSuccess) {
            setEditting(false);
        }
        if (mutatePriority.isError) {
        }
    }, [mutatePriority.isSuccess, mutatePriority.isError]);

    const renderEdit = (rules: RuleForMailbox[]) => {
        if (editting) {
            return (
                <>
                    <EditPriorityTable rules={rules} saveChanges={mutatePriority.mutate} />
                    <Button onClick={() => setEditting(false)}>Annuler</Button>
                </>
            );
        } else {
            return (
                <>
                    <PriorityTable rules={rules} />
                    <Button onClick={() => setEditting(true)}>Rediger</Button>
                </>
            );
        }
    };

    return (
        <>
            <MainMailboxSelect
                disabled={editting}
                availableMailboxes={mainMailboxes}
                selected={selectedMailbox}
                setMailbox={setSelectedMailbox}
            ></MainMailboxSelect>
            {RulesForMailbox.data && renderEdit(RulesForMailbox.data)}
        </>
    );
}
