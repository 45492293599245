import { faSortAlphaDown, faSortAlphaUp, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, SortDirection, SortingState, useReactTable } from '@tanstack/react-table';
import React from 'react';
import { useState } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { IAuditLog } from '../../scripts/ApiTypes';
import { EmailDisplay } from '../EmailDisplay/EmailDisplay';
import styles from './AuditlogTable.module.scss';

const AuditLogTable = ({
    data,
    className,
    setControlledSorting,
    initialState,
}: {
    data: IAuditLog[];
    className?: string;
    setControlledSorting: (id: string, desc: boolean) => any;
    initialState?: { id: string; desc: boolean };
}) => {
    const [sorting, setSorting] = useState<SortingState>(initialState ? [initialState] : []);

    const columns: ColumnDef<IAuditLog>[] = [
        {
            accessorFn: (row) => new Date(row.date).toLocaleString(),
            id: 'timestamp',
            header: 'Tidspunkt',
            sortingFn: 'datetime',
        },
        {
            accessorFn: (row) => row.email.sender,
            id: 'sender',
            header: 'Afsender',
        },
        {
            accessorFn: (row) => row.destinations.join(' \n '),
            id: 'destinations',
            header: 'Fordelt til',
            enableSorting: false,
        },
        {
            accessorFn: (row) => row.email.subject,
            id: 'subject',
            header: 'Emne',
        },
        {
            cell: (props) => {
                switch (props.row.original.callType) {
                    case 0:
                        return (
                            <a
                                href={'/rule-details/' + props.row.original.ruleId + '?ts=' + props.row.original.date}
                            >{`Regel: ${props.row.original.ruleName}`}</a>
                        );
                    case 1:
                        return `AI: ${(props.row.original.modelConfidence * 100).toFixed(2)}%`;
                    case 2:
                        return 'Manuel behandling';
                    default:
                        return 'Ukendt';
                }
            },
            id: 'callType',
            header: 'Fordelingsmetode',
        },
    ];

    const table = useReactTable({
        data: data,
        columns,
        state: {
            sorting: sorting,
        },
        manualSorting: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    React.useEffect(() => {
        if (sorting[0]) {
            setControlledSorting(sorting[0].id, sorting[0].desc);
        }
    }, [sorting]);

    const sortIcon = (sorted: false | SortDirection) => {
        if (!sorted) {
            return '';
        } else if (sorted === 'asc') {
            return <FontAwesomeIcon icon={faSortAlphaDown} />;
        } else {
            return <FontAwesomeIcon icon={faSortAlphaUp} />;
        }
    };

    const renderAITooltip = (auditlogEntry: IAuditLog) => {
        if (auditlogEntry.callType === 1) {
            return undefined;
        } else {
            if (auditlogEntry.aiClassification) {
                return (
                    <p>
                        AI resultat:{' '}
                        <Link to="/XAI" state={auditlogEntry.email.body + ' ' + auditlogEntry.email.attachmentText}>{`${auditlogEntry.aiClassification} (${(
                            auditlogEntry.modelConfidence * 100
                        ).toFixed(2)}%)`}</Link>
                    </p>
                );
            } else {
                return (
                    <OverlayTrigger
                        placement={'top'}
                        overlay={<Tooltip>Dette kan skyldes at AI motoren ikke var tilgængelig da emailen blev sorteret.</Tooltip>}
                    >
                        <p className="w-fit">
                            AI resultat: Ikke tilgængeligt <FontAwesomeIcon icon={faCircleInfo} />
                        </p>
                    </OverlayTrigger>
                );
            }
        }
    };

    return (
        <div className={'shadow-md mb-2 ' + className}>
            <div className={styles.TableHeader}>
                {table.getFlatHeaders().map((header) => (
                    <span
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className={header.column.getCanSort() ? 'hover:cursor-pointer' : ''}
                    >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {sortIcon(header.column.getIsSorted())}
                    </span>
                ))}
            </div>
            <Accordion className={styles.Accordion}>
                {table.getRowModel().rows.map((row) => (
                    <Accordion.Item key={row.id} eventKey={row.id}>
                        <Accordion.Header>
                            {row.getVisibleCells().map((cell) => (
                                <span key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
                            ))}
                        </Accordion.Header>
                        <Accordion.Body>
                            {renderAITooltip(row.original)}
                            <div className="border p-4">
                                <EmailDisplay email={row.original.email}></EmailDisplay>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
};

export default AuditLogTable;
