import { useGetUserRoles } from '../../hooks/UseGetUserRoles';
import { useGetRoles } from '../../hooks/UsetGetRoles';
import { RoleTable } from './RoleTable';

export function RolesAdminPage() {
    const userRoles = useGetUserRoles();
    const roles = useGetRoles();

    const roleTable = () => {
        if (userRoles.data && roles.data) {
            return <RoleTable roles={roles.data} userRoles={userRoles.data} />;
        } else {
            return undefined;
        }
    };

    return <div>{roleTable()}</div>;
}
