import { ResponsiveBar } from '@nivo/bar';

export type thresholdMailboxDataEntry = {
    Inkorrekt: number;
    Korrekt: number;
    Manuel: number;
    Mailbox: string;
};

export const ThresholdMailboxGraph = ({ data }: { data: thresholdMailboxDataEntry[] }) => {
    return (
        <div style={{ height: 150 + (data?.length ?? 0) * 50 + 'px' }}>
            <h3 className="text-center">Postfordeling pr. indbakke</h3>
            <ResponsiveBar
                data={data}
                keys={['Manuel', 'Korrekt', 'Inkorrekt']}
                indexBy="Mailbox"
                margin={{ top: 20, right: 130, bottom: 120, left: 300 }}
                padding={0.3}
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={['#A7C7E7', '#77DD77', '#FF6961']}
                borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Fordelingsprocent',
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                enableGridX={true}
                valueFormat=" >-.2%"
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        translateX: 100,
                        itemWidth: 80,
                        itemHeight: 20,
                        itemTextColor: '#999999',
                        symbolSize: 12,
                        symbolShape: 'square',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000000',
                                },
                            },
                        ],
                    },
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={function (e) {
                    return e.id + ': ' + e.formattedValue + ' in country: ' + e.indexValue;
                }}
            />
        </div>
    );
};
