import { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { passwordChangeResponse, requestPasswordChange } from '../../../scripts/AuthenticationServices';

export const Password = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [newConfirmPassword, setNewConfirmPassword] = useState<string>('');
    const [failedChangePassword, setFailedPasswordChange] = useState<boolean>(false);

    const formatMessage = (message: string) => {
        const strList = message.trim().split('\n');
        if (strList.length > 1) {
            return (
                <ul className="mb-0">
                    {strList.map((item, i) => (
                        <li key={i}>{item}</li>
                    ))}
                </ul>
            );
        }
        return message;
    };

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        requestPasswordChange({ currentPassword: oldPassword, newPassword: newPassword, newPasswordConfirm: newConfirmPassword })
            .then((res) => {
                setFailedPasswordChange(false);
                setMessage('Dit password blev ændret');
                setOldPassword('');
                setNewPassword('');
                setNewConfirmPassword('');
                setLoading(false);
            })
            .catch((er) => {
                const body = er.response.data as passwordChangeResponse;
                setFailedPasswordChange(true);
                if (body.isCurrentPasswordInvalid) {
                    setMessage('Du har indtastet dit nuværende password forkert');
                } else if (body.isNewPasswordInvalid) {
                    setMessage('Det nye password overholder ikke alle krav: \n' + body.message);
                } else if (body.isConfirmPasswordInvalid) {
                    setMessage('De nye passwords matcher ikke hinanden');
                }
                setLoading(false);
            });
    };

    return (
        <>
            <h4>Skift password</h4>
            {message && <Alert variant={failedChangePassword ? 'danger' : 'success'}>{formatMessage(message)}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Form.Label>Nuværende password</Form.Label>
                <Form.Control
                    value={oldPassword}
                    onChange={(e) => setOldPassword(e.currentTarget.value)}
                    type="password"
                    placeholder="Indtast nuværende password..."
                    className="mb-2"
                />
                <Form.Label>Nyt password</Form.Label>
                <Form.Control
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.currentTarget.value)}
                    type="password"
                    placeholder="Indtast nyt password..."
                    className="mb-2"
                />
                <Form.Label>Gentag nyt password</Form.Label>
                <Form.Control
                    value={newConfirmPassword}
                    onChange={(e) => setNewConfirmPassword(e.currentTarget.value)}
                    type="password"
                    placeholder="Gentag nyt password..."
                    className="mb-2"
                />
                <Button disabled={loading} type="submit">
                    Skift password
                </Button>
            </Form>
        </>
    );
};
