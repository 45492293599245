import { ColumnDef, Sorting, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { RuleForMailbox } from '../../hooks/UseGetRulesForMailbox';
import { Table } from 'react-bootstrap';

type props = {
    rules: RuleForMailbox[];
};

export function PriorityTable({ rules }: props) {
    const columns: ColumnDef<RuleForMailbox>[] = [
        {
            accessorFn: (row) => row.priority + 1,
            id: 'priority',
            header: 'Nr',
        },
        {
            accessorFn: (row) => row.rule.name,
            id: 'name',
            header: 'Navn',
        },
        {
            accessorFn: (row) => (row.rule.fallthrough ? 'Aktiveret' : 'Deaktiveret'),
            id: 'ruleText',
            header: 'Regelmotor fortsættelse',
        },
    ];

    const table = useReactTable({
        data: rules,
        columns,
        initialState: {
            sorting: [
                {
                    id: 'priority',
                    desc: false,
                },
            ],
        },
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <div className="shadow-sm">
            <Table className="border-separate border-spacing-0 bg-gray-100">
                <thead className="bg-main-500 text-gray-100">
                    <tr>
                        {table.getFlatHeaders().map((header) => (
                            <th key={header.id}>{flexRender(header.column.columnDef.header, header.getContext())}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {table.getRowModel().rows.map((row) => (
                        <tr>
                            {row.getVisibleCells().map((cell) => (
                                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
}
