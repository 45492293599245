import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Alert extends Component {
    render() {
        const c = 'alert alert-' + (this.props.isDanger ? 'danger' : 'success');
        return (
            <div className={c} role="alert" hidden={this.props.hidden}>
                <button type="button" className="close" aria-label="Close" onClick={() => this.props.hideFun()}>
                    <span aria-hidden="true">×</span>
                </button>
                {this.props.message}
            </div>
        );
    }
}

Alert.propTypes = {
    message: PropTypes.string,
    isDanger: PropTypes.bool,
    hideFun: PropTypes.func,
};
