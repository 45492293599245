import Form from 'react-bootstrap/Form';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type DateRangeProps = {
    from: Date;
    to: Date;
    setFrom: (d: Date) => void;
    setTo: (d: Date) => void;
    className?: string;
};

export const DateRange = ({ from, to, setFrom, setTo, className }: DateRangeProps) => {
    return (
        <div className={'flex ' + className}>
            <Form.Control
                required
                type="date"
                defaultValue={from.toJSON().slice(0, 10)}
                className="border-r-0 rounded-r-none pr-0"
                onChange={(e) => {
                    let d = new Date(e.currentTarget.value);
                    setFrom(d);
                }}
            />
            <div className="border-y border-gray-50 bg-gray-100 flex items-center">
                <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>
            </div>
            <Form.Control
                required
                type="date"
                defaultValue={to.toJSON().slice(0, 10)}
                className="border-l-0 rounded-l-none pr-0"
                onChange={(e) => {
                    let d = new Date(e.currentTarget.value);
                    setTo(d);
                }}
            />
        </div>
    );
};
