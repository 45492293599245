import { Dropdown, Form } from 'react-bootstrap';
import { UseGetMainMailboxes } from '../../hooks/UseGetMainMailboxes';

type props = {
    onToggle: (e: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    selectedIds: number[];
};

export function MailboxDropDown({ onToggle, selectedIds }: props) {
    const mainMailboxes = UseGetMainMailboxes();

    return (
        <Form.Group>
            <Form.Label>Hovedpostkasser:</Form.Label>
            <Dropdown>
                {mainMailboxes.data && (
                    <>
                        <Dropdown.Toggle
                            variant="outline-secondary"
                            className="w-52 flex justify-between items-center text-black border-gray-400 form-control hover:bg-white"
                        >
                            Postkasser
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="px-3 w-52">
                            {mainMailboxes.data.map((mailbox) => (
                                <Form.Check
                                    checked={selectedIds.includes(mailbox.id)}
                                    key={mailbox.id}
                                    type="checkbox"
                                    label={mailbox.address}
                                    onChange={(e) => onToggle(e, mailbox.id)}
                                />
                            ))}
                        </Dropdown.Menu>
                    </>
                )}
            </Dropdown>
        </Form.Group>
    );
}
