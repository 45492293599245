import { Form } from 'react-bootstrap';
import { MainMailbox, UseGetMainMailboxes } from '../../hooks/UseGetMainMailboxes';

type props = {
    availableMailboxes: MainMailbox[];
    selected: number;
    setMailbox: (id: number) => void;
    disabled?: boolean;
};

export function MainMailboxSelect({ selected, setMailbox, availableMailboxes, disabled }: props) {
    return (
        <>
            <Form.Label>Vælg hovedpostkasse:</Form.Label>
            <Form.Select
                disabled={disabled}
                value={selected}
                onChange={(e) => {
                    setMailbox(+e.target.value);
                }}
                className="mb-4"
            >
                {availableMailboxes.map((mailbox) => (
                    <option key={mailbox.id} value={mailbox.id}>
                        {mailbox.address}
                    </option>
                ))}
            </Form.Select>
        </>
    );
}
