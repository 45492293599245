import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useState } from 'react';
import { Alert, Button, Form } from 'react-bootstrap';
import { ClipLoader } from 'react-spinners';
import Auth from '../../../authentication/Auth';
import { requestChangeEmail } from '../../../scripts/AuthenticationServices';

export const Email = ({ email }: { email: string }) => {
    const [newEmail, setNewEmail] = useState<string>('');

    const changeEmail = useQuery<AxiosResponse, AxiosError>({ queryKey: ['change email'], queryFn: () => requestChangeEmail(newEmail), enabled: false });

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        changeEmail.refetch();
    };

    return (
        <>
            <div className="w-full h-full relative">
                {changeEmail.isFetching && <ClipLoader className="absolute z-10 m-auto left-0 right-0 top-0 bottom-0"></ClipLoader>}
                <div className={changeEmail.isFetching ? 'opacity-50' : undefined}>
                    <h4>Skift email</h4>
                    {changeEmail.isSuccess && <Alert variant="sucess">En bekræftigelse er sendt til din nye email.</Alert>}
                    {changeEmail.isError && (
                        <Alert variant="danger">
                            {changeEmail.error.response?.status === 400
                                ? 'Den indtastede email er enten ugyldig eller ikke understøttet af systemet.'
                                : 'Noget gik galt. Din email er ikke blevet ændret.'}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Label>Email</Form.Label>
                        <Form.Control className="mb-2" disabled value={email}></Form.Control>
                        <Form.Label>Ny email</Form.Label>
                        <Form.Control className="mb-2" onChange={(e) => setNewEmail(e.currentTarget.value)} placeholder="Indtast ny email..."></Form.Control>
                        <Button disabled={changeEmail.isFetching} type="submit">
                            Skift email
                        </Button>
                    </Form>
                </div>
            </div>
        </>
    );
};
