import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { Protected } from './authentication/Protected.Route';
import { RuleList } from './components/RuleList/RuleList';
import { RuleCreation } from './components/RuleCreation/RuleCreation';
import { RuleDetails } from './components/RuleDetails/RuleDetails';
import { Account } from './components/Account/Account';
import { AuditLog } from './components/Auditlog/Auditlog';
import { SharedMailboxes } from './components/SharedMailBoxes/SharedMailboxes';
import { AddMailBoxForm } from './components/SharedMailBoxes/AddMailBoxForm';
import { LoginForm, ForgotPassword } from './authentication/Login';
import { ResetPassword } from './authentication/ResetPassword';
import Statistics from './components/Statistics/Statistics';
import { Layout } from './components/Layout/Layout';
import { Confirm } from './components/Account/Confirm';
import { SortingTest } from './components/SortingTest/SortingTest';
import { OnboardingPage } from './components/Onboarding/OnboardingPage';
import { getFeatures } from './scripts/DataServices';
import { AIControlPanel } from './components/AIControl/AIControlPage';
import { Unauthorized } from './authentication/Unauthorized';
import { RulePriorityNew } from './components/RulePriority/RulePriority';
import { RolesAdminPage } from './components/Roles/RolesAdminPage';

export const App = () => {
    const features = useQuery({ queryKey: ['features'], queryFn: getFeatures });

    return (
        <BrowserRouter>
            <Layout>
                <Routes>
                    <Route element={<Protected />}>
                        <Route index element={<RuleList />} />
                        <Route path="/rule-details" element={<RuleDetails />} />
                        <Route path="/rule-details/:id" element={<RuleDetails />} />
                        <Route path="/rule-creation" element={<RuleCreation />}></Route>
                        <Route path="/rule-creation/:id" element={<RuleCreation />} />
                        <Route path="/sorting-test" element={<SortingTest />} />
                        <Route path="/auditlogs" element={<AuditLog />} />
                        <Route path="/sharedinboxes" element={<SharedMailboxes />} />
                        <Route path="/account" element={<Account />} />
                        <Route path="/priorities" element={<RulePriorityNew />} />
                        <Route path="/statistics" element={<Statistics />} />
                        <Route path="/add-mailbox" element={<AddMailBoxForm />} />
                        <Route path="/ai" element={<AIControlPanel />} />
                        {features.data?.onboarding && <Route path="/onboarding" element={<OnboardingPage />} />}
                        <Route path="/roles" element={<RolesAdminPage />}></Route>
                    </Route>

                    <Route path="/login" element={<LoginForm />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/confirm-email" element={<Confirm />} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                </Routes>
            </Layout>
        </BrowserRouter>
    );
};

export default App;
