import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useNavigate } from 'react-router-dom';
import { RuleFormData } from './RuleCreation';

type FormProps = {
    nextPage: () => void;
    setFormData: (f: RuleFormData) => void;
    formData: RuleFormData;
};

export const NameForm = ({ nextPage, setFormData, formData }: FormProps) => {
    const [validated, setValidated] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            nextPage();
        }
        setValidated(true);
    };

    return (
        <>
            <h2 className="text-center">Vælg reglens navn</h2>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group className="py-4 border-y-2 mb-4">
                    <Form.Control
                        defaultValue={formData.name}
                        type="text"
                        required
                        onChange={(e) => {
                            formData.name = e.currentTarget.value;
                            setFormData({ ...formData });
                        }}
                        placeholder="navn..."
                    />
                    <Form.Control.Feedback type="invalid">Indtast et navn.</Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="flex justify-between">
                    <Button onClick={() => navigate(-1)} variant="danger">
                        Anullér
                    </Button>
                    <Button type="submit">Næste</Button>
                </Form.Group>
            </Form>
        </>
    );
};
