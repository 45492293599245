import { useMutation, useQuery } from '@tanstack/react-query';
import { getDisclaimerText, setDisclaimerText } from '../../scripts/DataServices';
import { Button, Form, Modal } from 'react-bootstrap';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';

export default function DisclaimerText() {
    const textQuery = useQuery({ queryKey: ['disclaimerText'], queryFn: getDisclaimerText });

    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <div className="mb-2">
            <h2 className="text-xl">SmartMail fordelingstekst</h2>
            <p>Videresend fordelte mails med følgende tekst:</p>
            {textQuery.isError && <p className="mx-4">Der opstod en fejl der fordelingsteksten skulle hentes.</p>}
            {textQuery.isLoading && (
                <Form>
                    <ClipLoader></ClipLoader>
                    <br />
                    <Button disabled>Rediger tekst</Button>
                </Form>
            )}
            {textQuery.data !== undefined && (
                <>
                    <Form>
                        <Form.Control disabled className="mb-2" value={textQuery.data} as="textarea" rows={3}></Form.Control>
                        <Button onClick={() => setShowModal(true)}>Rediger tekst</Button>
                    </Form>
                    <DisclaimerTextEdit
                        invalidate={() => textQuery.refetch()}
                        initialText={textQuery.data ?? ''}
                        showModal={showModal}
                        setShowModal={setShowModal}
                    ></DisclaimerTextEdit>
                </>
            )}
        </div>
    );
}

type props = {
    showModal: boolean;
    setShowModal: (b: boolean) => void;
    initialText: string;
    invalidate: () => void;
};

function DisclaimerTextEdit({ setShowModal, showModal, initialText, invalidate }: props) {
    const [text, setText] = useState<string>(initialText);

    const submit = async (input: string) => {
        await setDisclaimerText(input);
        invalidate();
        setShowModal(false);
    };

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Opdater fordelingstekst</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    <Form.Control as="textarea" rows={3} value={text} onChange={(e) => setText(e.target.value)}></Form.Control>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => submit(text)}>
                    Gem tekst
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
