import { QueryClient, useMutation, useQueryClient } from '@tanstack/react-query';
import { api } from '../scripts/DataServices';
import { CacheKey } from './CacheKeys';
import toast from 'react-hot-toast';

type rulePriorityUpdate = number[];

export function UseMutateRulePriorities(mailboxId: number) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (rulePriorityUpdate: rulePriorityUpdate) =>
            api.put('rules/update-priorities', {
                ruleIds: rulePriorityUpdate,
                mailboxId: mailboxId,
            }),
        onSuccess: () => {
            toast.success('Prioriteringen blev gemt!');
            queryClient.invalidateQueries({ queryKey: CacheKey.RulesForMailbox(mailboxId) });
        },
        onError: () => {
            toast.error('Der opstod en fejl');
        },
    });
}
