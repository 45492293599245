import { ResponsiveBar } from '@nivo/bar';

type statisticsData = {
    [key: string]: any;
};

type chartProps = {
    data: statisticsData[];
    logScale?: boolean;
    ruleView: boolean;
};

export const BarChart = ({ data, ruleView, logScale = false }: chartProps) => {
    const theme = {
        fontSize: 15,
    };

    const powerBiColors = ruleView ? ['#FD625E', '#01B8AA'] : ['#01B8AA'];

    return (
        <div style={{ height: `${100 + data.length * 40}px` }}>
            <ResponsiveBar
                data={data}
                keys={data.length > 0 ? ('AI lighed' in data[0] ? ['Sorteret', 'AI lighed'] : ['Sorteret']) : []}
                indexBy="Id"
                margin={{ top: 100, right: 50, left: 200 }}
                padding={0.2}
                valueScale={{ type: logScale ? 'symlog' : 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={powerBiColors}
                theme={theme}
                borderColor={{ from: 'color', modifiers: [['darker', 1.3]] }}
                axisTop={null}
                axisRight={null}
                layout="horizontal"
                groupMode="grouped"
                axisLeft={{
                    tickSize: 10,
                    tickPadding: 5,
                    tickRotation: 30,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: 32,
                }}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Antal fordelinger',
                    legendPosition: 'middle',
                    legendOffset: 50,
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor="white"
                legends={[]}
                animate={true}
            />
        </div>
    );
};
